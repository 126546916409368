<template>
  <div>
    服装品牌
  </div>
</template>
<script>
    export default {
        name: 'Fashion',
        data () {
            return {

            }
        }
    }
</script>

<style scoped>
  *{
    box-sizing: border-box;
    margin:0;
    padding:0;
  }
  .img{
    height:500px;
    width:100%;
  }
</style>