<template>
    <div class="partners-page">
        <!-- 顶部横幅 -->
        <section class="hero-section">
            <div class="hero-content">
                <h1>合作伙伴</h1>
                <p>
                    弘法科技有限公司致力于与全球行业领先企业共同合作，构建创新、共赢的生态体系。
                </p>
            </div>
        </section>

        <!-- 合作伙伴展示 -->
        <section class="partners-gallery">
            <h2>我们的合作伙伴</h2>
            <p class="gallery-intro">
                通过与行业顶尖合作伙伴的协作，我们不断突破行业边界，共同开创未来。
            </p>
            <div class="partners-grid">
                <div class="partner-card" v-for="(partner, index) in partners" :key="index">
                    <img :src="partner.logo" :alt="partner.name" />
                </div>
            </div>
        </section>

        <!-- 合作价值观 -->
        <section class="partnership-values">
            <h2>合作价值观</h2>
            <div class="values-grid">
                <div class="value-card" v-for="(value, index) in values" :key="index">
                    <div class="icon">{{ value.icon }}</div>
                    <h3>{{ value.title }}</h3>
                    <p>{{ value.description }}</p>
                </div>
            </div>
        </section>

        <!-- 合作伙伴分类 -->
        <section class="partners-categories">
            <h2>合作伙伴分类</h2>
            <div class="categories-grid">
                <div class="category-card" v-for="(category, index) in categories" :key="index">
                    <div class="icon">{{ category.icon }}</div>
                    <h3>{{ category.title }}</h3>
                    <p>{{ category.description }}</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "PartnersPage",
    data() {
        return {
            partners: [
                {
                    logo: require("@/assets/cp-am.png"),
                },
                {
                    logo: require("@/assets/cp-by.png"),
                },
                {
                    logo: require("@/assets/cp-jd.png"),
                },
                {
                    logo: require("@/assets/o-cp-ht.png"),
                },
            ],
            values: [
                {
                    icon: "🤝",
                    title: "信任与共赢",
                    description: "以信任为基础，共创商业与社会价值。",
                },
                {
                    icon: "🌍",
                    title: "全球化合作",
                    description: "建立全球战略合作伙伴关系，共同开拓国际市场。",
                },
                {
                    icon: "📈",
                    title: "创新驱动",
                    description: "推动技术创新，共同引领行业发展方向。",
                },
                {
                    icon: "💼",
                    title: "深度协作",
                    description: "通过深度合作，为客户提供优质服务和产品。",
                },
            ],
            categories: [
                {
                    icon: "🔧",
                    title: "技术合作伙伴",
                    description: "专注于技术研发，与全球领先企业协作创新。",
                },
                {
                    icon: "📦",
                    title: "供应链合作伙伴",
                    description: "优化供应链体系，提升产品交付效率。",
                },
                {
                    icon: "💡",
                    title: "创新伙伴",
                    description: "合作开发新技术和新模式，推动行业变革。",
                },
                {
                    icon: "📊",
                    title: "市场合作伙伴",
                    description: "共同推动品牌价值和市场占有率提升。",
                },
            ],
        };
    },
};
</script>

<style scoped>
/* 整体页面样式 */
.partners-page {
    font-family: "Arial", sans-serif;
    color: #333;
    line-height: 1.6;
}

/* 顶部横幅 */
.hero-section {
    background: linear-gradient(to right, #3498db, #2c3e50);
    color: white;
    text-align: center;
    padding: 60px 20px;
}

.hero-section h1 {
    font-size: 3em;
    margin-bottom: 10px;
}

.hero-section p {
    font-size: 1.2em;
    margin-top: 10px;
    opacity: 0.9;
}

/* 合作伙伴展示 */
.partners-gallery {
    padding: 40px 20px;
    background: #f4f6f8;
    text-align: center;
}

.partners-gallery h2 {
    font-size: 2em;
    margin-bottom: 10px;
}

.gallery-intro {
    font-size: 1.2em;
    color: #7f8c8d;
    margin-bottom: 20px;
}

.partners-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.partner-card {
    background: #ffffff;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.partner-card:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.partner-card img {
    width: 100%;
    max-width: 150px;
    height: auto;
    margin-bottom: 10px;
}

/* 合作价值观 */
.partnership-values {
    padding: 40px 20px;
    background: #ffffff;
    text-align: center;
}

.partnership-values h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.values-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.value-card {
    background: #ffffff;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.value-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.value-card .icon {
    font-size: 2.5em;
    color: #3498db;
    margin-bottom: 10px;
}

/* 合作伙伴分类 */
.partners-categories {
    padding: 40px 20px;
    background: #f9fafc;
    text-align: center;
}

.categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.category-card {
    background: #ffffff;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.category-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.category-card .icon {
    font-size: 2.5em;
    color: #e67e22;
    margin-bottom: 10px;
}
</style>
