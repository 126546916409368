<template>
  <div class="about-us">
    <section class="hero">
      <h1>关于我们</h1>
      <p>
        我们是一家专注于信息技术创新的公司，致力于为客户提供高质量的解决方案，
        以技术驱动未来。
      </p>
    </section>

    <section class="core-values-section">
      <h2>我们的核心价值观</h2>
      <div class="core-values">
        <div class="card" v-for="(value, index) in coreValues" :key="index">
          <div class="icon">{{ value.icon }}</div>
          <h3 class="value-title">{{ value.title }}</h3>
          <p class="value-description">{{ value.description }}</p>
        </div>
      </div>
    </section>

    <section class="team-section">
      <h2>我们的团队</h2>
      <div class="team">
        <div class="team-card" v-for="(member, index) in team" :key="index">
          <img :src="member.image" alt="团队成员照片" />
          <h3>{{ member.name }}</h3>
          <p>{{ member.position }}</p>
        </div>
      </div>
    </section>

    <section class="vision-section">
      <h2>我们的愿景</h2>
      <p>
        我们的目标是成为全球领先的信息技术解决方案提供商，通过不断创新和追求卓越，
        为客户和社会创造更多价值。
      </p>
    </section>
  </div>
</template>

<script>
//在项目终端安装echarts：npm install echarts --save

export default {
  name: "AboutMe",
  data() {
    return {
      coreValues: [
        { icon: "🚀", title: "创新", description: "推动技术进步，引领行业发展。" },
        { icon: "🤝", title: "团队合作", description: "凝聚团队力量，共同实现目标。" },
        { icon: "🎯", title: "客户为中心", description: "满足客户需求，创造最佳体验。" },
        { icon: "🌍", title: "社会责任", description: "为社会创造价值，履行企业责任。" },
        { icon: "📈", title: "追求卓越", description: "精益求精，超越自我，追求卓越。" },
        { icon: "💡", title: "学习成长", description: "不断学习，共同成长，实现共赢。" },
      ],
      team: [
        {
          name: "李小明",
          position: "首席执行官",
          image: "https://randomuser.me/api/portraits/men/32.jpg",
        },
        {
          name: "张晓华",
          position: "技术总监",
          image: "https://randomuser.me/api/portraits/women/44.jpg",
        },
        {
          name: "王小丽",
          position: "产品经理",
          image: "https://randomuser.me/api/portraits/women/15.jpg",
        },
        {
          name: "陈小强",
          position: "市场总监",
          image: "https://randomuser.me/api/portraits/men/87.jpg",
        },
      ],
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped>
/* 全局样式 */
.about-us {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

/* 头部介绍 */
.hero {
  text-align: center;
  padding: 40px 20px;
  background-color: #f4f6f8;
}

.hero h1 {
  font-size: 2.5em;
  margin-bottom: 15px;
  color: #2c3e50;
}

.hero p {
  font-size: 1.2em;
  color: #7f8c8d;
}

/* 核心价值观 */
.core-values-section {
  padding: 40px 20px;
  text-align: center;
  background-color: #ffffff;
}

.core-values-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #2c3e50;
}

.core-values {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.card {
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-10px) scale(1.03);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 2.5em;
  color: #3498db;
  margin-bottom: 15px;
}

.value-title {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 10px;
}

.value-description {
  font-size: 1em;
  color: #7f8c8d;
}

/* 团队介绍 */
.team-section {
  padding: 40px 20px;
  background-color: #f9fafc;
  text-align: center;
}

.team-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #2c3e50;
}

.team {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.team-card {
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-card:hover {
  transform: translateY(-10px) scale(1.03);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.team-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.team-card h3 {
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #2c3e50;
}

.team-card p {
  font-size: 1em;
  color: #7f8c8d;
}

/* 愿景部分 */
.vision-section {
  padding: 40px 20px;
  text-align: center;
  background-color: #ffffff;
}

.vision-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #2c3e50;
}

.vision-section p {
  font-size: 1.2em;
  color: #7f8c8d;
  line-height: 1.8;
}
</style>