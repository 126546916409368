<template>
  <div>
    <div><img src="../assets/baner.jpg" class="fruit_img" /></div>
    <!-- 卡片展示 -->
    <section class="solutions-section">
      <h2>专属解决方案</h2>
      <p>针对您的业务需求，我们提供灵活且全面的解决方案。</p>

      <div class="solutions-grid">
        <!-- 卡片 1 -->
        <div class="solution-card">
          <img src="https://via.placeholder.com/100?text=AI" alt="AI解决方案">
          <h3>人工智能解决方案</h3>
          <p>利用AI技术优化业务流程，提升数据洞察力与自动化能力。</p>
        </div>

        <!-- 卡片 2 -->
        <div class="solution-card">
          <img src="https://via.placeholder.com/100?text=Cloud" alt="云计算解决方案">
          <h3>云计算解决方案</h3>
          <p>提供灵活高效的云服务架构，助力企业实现数字化转型。</p>
        </div>

        <!-- 卡片 3 -->
        <div class="solution-card">
          <img src="https://via.placeholder.com/100?text=IoT" alt="物联网解决方案">
          <h3>物联网解决方案</h3>
          <p>连接设备与数据，实现智能化管理与实时监控。</p>
        </div>

        <!-- 卡片 4 -->
        <div class="solution-card">
          <img src="https://via.placeholder.com/100?text=Security" alt="安全解决方案">
          <h3>安全解决方案</h3>
          <p>多层次的安全防护，保障企业数据与系统免受威胁。</p>
        </div>

        <!-- 卡片 5 -->
        <div class="solution-card">
          <img src="https://via.placeholder.com/100?text=Mobile" alt="移动应用解决方案">
          <h3>移动应用解决方案</h3>
          <p>开发高性能移动应用，实现便捷的移动化服务体验。</p>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
export default {
  name: 'Fruit',
  data() {
    return {
      delivery: [
        {
          img: require('../assets/other/定位图标.png'),
          title: "自定义配送范围",
          contents: ["商家后台自定义配送范围"]
        }, {
          img: require('../assets/other/骑车送.png'),
          title: "支持自定义配送",
          contents: ["商家自己优选配送路径"]
        }, {
          img: require('../assets/other/券.png'),
          title: "线上优惠券福利",
          contents: ["各种优惠福利提升用户消费"]
        }
      ]
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;

}

.fruit_img {
  width: 100%;
}

/* 顶部大图部分 */
.hero-image {
  width: 100%;
  height: 400px;
  background: url("https://via.placeholder.com/1920x400?text=解决方案大图") center/cover no-repeat;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-image h1 {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* 卡片展示部分 */
.solutions-section {
  padding: 60px 20px;
  background-color: #ffffff;
  text-align: center;
}

.solutions-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.solutions-section p {
  font-size: 1.1rem;
  color: #7f8c8d;
  margin-bottom: 40px;
  max-width: 600px;
  margin: 0 auto;
}

.solutions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* 解决方案卡片 */
.solution-card {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.solution-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.solution-card img {
  max-width: 100px;
  margin-bottom: 20px;
}

.solution-card h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.solution-card p {
  font-size: 1rem;
  color: #7f8c8d;
  line-height: 1.6;
}

/* 响应式设计 */
@media screen and (max-width: 768px) {
  .hero-image h1 {
    font-size: 2.5rem;
  }

  .solutions-grid {
    grid-template-columns: 1fr;
  }
}
</style>