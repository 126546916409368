/*引入vue框架*/
import Vue from "vue";
/*引入路由依赖*/
import Router from "vue-router";
/*引入页面组件，命名为Index*/
import Index from "../components/Index";
import Fruit from "../components/Fruit";
import Fashion from "../components/Fashion";
import HelloWorld from "../components/HelloWorld";
import AboutMe from "../components/AboutMe";
import Partner from "../components/Partner";
import Product from "@/components/Product.vue";

/*使用路由依赖*/
Vue.use(Router);

/*定义路由*/
export default new Router({
  routes: [
    {
      path: "/",
      name: "Index",
      component: Index,
    },
    {
      path: "/fruit",
      name: "Fruit",
      component: Fruit,
    },
    {
      path: "/fashion",
      name: "Fashion",
      component: Fashion,
    },
    {
      path: "/helloworld",
      name: "HelloWorld",
      component: HelloWorld,
    },
    {
      path: "/product",
      name: "Product",
      component: Product,
    },
    {
      path: "/partner",
      name: "Partner",
      component: Partner,
    },
    {
      path: "/aboutme",
      name: "aboutme",
      component: AboutMe,
    },
  ],
});
