<template>
    <div class="product-container">
        <!-- 产品特点-->
        <!-- <div id="产品特点" class="product_spec_title">
            <div style="margin: auto;width: 100%;" class="font_size_24">
                产品特点
            </div>
        </div> -->
        <section class="hero-section">
            <div class="hero-content">
                <h1>产品特点</h1>
                <p>探索我们的产品如何帮助您实现卓越性能与创新体验。</p>
            </div>
        </section>
        <div class="text-container">
            <div class="text-item">
                <h3>极速性能</h3>
                <p>
                    拥有强大的技术架构，我们的产品以极致的速度运行，无论是复杂的计算还是高频的操作，都能迅速响应，助您快人一步。
                </p>
            </div>
            <div class="text-item">
                <h3>安全保障</h3>
                <p>
                    数据安全是我们的核心承诺。通过先进的加密技术和多层次的防护体系，您的隐私与数据安全得到了全方位的守护。
                </p>
            </div>
            <div class="text-item">
                <h3>移动优先</h3>
                <p>
                    针对移动设备深度优化的设计，让您在任何时间、任何地点，都能轻松访问并享受卓越体验。
                </p>
            </div>
            <div class="text-item">
                <h3>用户友好</h3>
                <p>
                    我们始终关注用户需求，界面设计直观易用，无需繁琐学习，打开即用，用心打造每一次愉快的使用体验
                </p>
            </div>
            <div class="text-item">
                <h3>持续创新</h3>
                <p>
                    创新是我们不断向前的动力。我们始终关注行业前沿，为用户提供领先技术和功能，不断定义行业新标准。
                </p>
            </div>
        </div>
        <div class="product_spec_parent">
            <div class="border_one border_spec">
                <div class="border_spec_inner_one">
                    <img :src="spec.product[0].img" style="width: 50px;height: 50px" />
                    <div style="margin-left:5px; font-weight: bold;" class="font_size_24">
                        {{ spec.product[0].title }}
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;">
                    <div class="border_spec_content_one">
                        {{ spec.product[0].contents[0] }}
                    </div>
                    <div class="border_spec_inner_last">
                        {{ spec.product[0].contents[1] }}
                    </div>
                </div>
            </div>
            <div>
                <img :src="computeArrowImg" class="border_spec_img" />
            </div>
            <div class="border_spec">
                <div class="border_spec_inner_one">
                    <img :src="spec.product[1].img" style="width: 50px;height: 50px" />
                    <div style="margin-left:5px; font-weight: bold;" class="font_size_24">
                        {{ spec.product[1].title }}
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;">
                    <div class="border_spec_content_one">
                        {{ spec.product[1].contents[0] }}
                    </div>
                    <div class="border_spec_inner_last">
                        {{ spec.product[1].contents[1] }}
                    </div>
                </div>
            </div>
            <div>
                <img :src="computeArrowImg" class="border_spec_img" />
            </div>
            <div class="border_spec">
                <div class="border_spec_inner_one">
                    <img :src="spec.product[2].img" style="width: 50px;height: 50px" />
                    <div style="margin-left:5px; font-weight: bold;" class="font_size_24">
                        {{ spec.product[2].title }}
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;">
                    <div class="border_spec_content_one">
                        {{ spec.product[2].contents[0] }}
                    </div>
                    <div class="border_spec_inner_last">
                        {{ spec.product[2].contents[1] }}
                    </div>
                </div>
            </div>
            <div>
                <img :src="computeArrowImg" class="border_spec_img" />
            </div>
            <div class="border_spec" style="margin-right: 0">
                <div class="border_spec_inner_one">
                    <img :src="spec.product[3].img" style="width: 50px;height: 50px" />
                    <div style="margin-left:5px; font-weight: bold;" class="font_size_24">
                        {{ spec.product[3].title }}
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;">
                    <div class="border_spec_content_one">
                        {{ spec.product[3].contents[0] }}
                    </div>
                    <div class="border_spec_inner_last">
                        {{ spec.product[3].contents[1] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            spec: {
                img: require('../../static/other/arrow.png'),
                product: [
                    {
                        img: require('../../static/other/手指.png'),
                        title: "一键建铺",
                        contents: ["注册一键创建店铺", "开启您的专属店铺"]
                    },
                    {
                        img: require('../../static/other/店铺管理.png'),
                        title: "店铺装扮",
                        contents: ["不同业态打造不同风格", "给用户流畅的视觉体验"]
                    },
                    {
                        img: require('../../static/other/数据分析.png'),
                        title: "营销分析",
                        contents: ["多玩法提升品牌知名度", "优惠福利刺激付费转化"]
                    },
                    {
                        img: require('../../static/other/助力零售.png'),
                        title: "助力零售",
                        contents: ["提供专业产品服务", "帮助线下零售业态提升营销"]
                    }
                ]
            },
        }
    },
    computed: {
        computeArrowImg() {
            let screenHeight = document.body.clientWidth;
            if (screenHeight > 450) {
                return require('../../static/other/arrow.png');
            } else {
                return require('../../static/other/arrow_down.png');
            }
        }
    },
    methods: {},
    created() { }
}
</script>

<style scoped>
/* 顶部横幅 */
.hero-section {
    background: linear-gradient(to right, #4a90e2, #50b2c0);
    color: white;
    text-align: center;
    padding: 60px 20px;
}

.hero-section h1 {
    font-size: 3em;
    margin-bottom: 10px;
}

.hero-section p {
    font-size: 1.2em;
    opacity: 0.9;
}

.product_spec_title {
    display: flex;
    margin-top: 30px;
    margin-bottom: 10px
}

.product_spec_parent {
    display: flex;
    width: 90%;
    margin: 20px auto;
    align-items: center;
    height: auto
}

.text-container {
    padding: 30px 100px;
}

.text-container .text-item {
    margin-bottom: 30px;
}

.text-container .text-item>h3 {
    margin-bottom: 10px;
}


.cooperation_parent {
    display: flex;
    width: 90%;
    margin: 20px auto;
    align-items: center;
    height: auto
}

.border_spec {
    border-radius: 6px;
    border: 1px solid #EBEEF5;
    float: left;
    width: 25%;
    height: auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.about_me_p {
    font-size: 1.25em;
    font-weight: normal;
    width: 60%;
    text-align: left;
    margin: 10px;
}

.border_title {
    font-family: "PingFang SC";
    margin-top: 10px;
    font-size: 1.125em;
    font-weight: bold;
}

.border_content_one {
    margin-top: 20px;
    font-size: 1.125em;
}

.border_content_two {
    margin-top: 5px;
    font-size: 1.125em;
    margin-bottom: 25px;
}

.img_border {
    margin-top: 15px;
    width: 80px;
    height: 80px
}

.border_spec_inner_one {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    /*background-color: aqua;*/
    height: 70px;
}

.border_spec_content_one {
    margin-top: 15px;
    font-size: 1.125em;
    float: left;
}

.border_spec_inner_last {
    /*background-color: bisque;*/
    margin-top: 15px;
    font-size: 1.125em;
    float: left;
    margin-bottom: 25px;
}
</style>
